import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-clarksville-tennessee.png'
import image0 from "../../images/cities/scale-model-of-customs-house-museum-and-cultural-center-in-clarksville-tennessee.png"
import image1 from "../../images/cities/scale-model-of-mcgregor-park-&-cumberland-riverwalk-in-clarksville-tennessee.png"
import image2 from "../../images/cities/scale-model-of-beachaven-vineyards-&-winery-in-clarksville-tennessee.png"
import image3 from "../../images/cities/scale-model-of-clarksville-montgomery-county-visitor-center-in-clarksville-tennessee.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Clarksville'
            state='Tennessee'
            image={image}
            lat='36.5297706'
            lon='-87.35945279999999'
            attractions={ [{"name": "Customs House Museum and Cultural Center", "vicinity": "200 S 2nd St, Clarksville", "types": ["museum", "store", "point_of_interest", "establishment"], "lat": 36.5261195, "lng": -87.35850260000001}, {"name": "McGregor Park & Cumberland Riverwalk", "vicinity": "640 N Riverside Dr, Clarksville", "types": ["park", "point_of_interest", "establishment"], "lat": 36.534912, "lng": -87.36736400000001}, {"name": "Beachaven Vineyards & Winery", "vicinity": "1100 Dunlop Ln, Clarksville", "types": ["liquor_store", "food", "store", "point_of_interest", "establishment"], "lat": 36.578392, "lng": -87.26107400000001}, {"name": "Clarksville-Montgomery County Visitor Center", "vicinity": "180 Holiday Dr, Clarksville", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 36.5959734, "lng": -87.28412270000001}] }
            attractionImages={ {"Customs House Museum and Cultural Center":image0,"McGregor Park & Cumberland Riverwalk":image1,"Beachaven Vineyards & Winery":image2,"Clarksville-Montgomery County Visitor Center":image3,} }
       />);
  }
}